<template>
  <div id="detail" style="width: 94%; margin: 0 auto">
    <el-button
      style="float: right"
      size="medium"
      type="primary"
      @click="dayin"
      v-show="Print"
      class="buttonNoPrint"
      >打印</el-button
    >

    <el-skeleton :loading="loading" />
    <div
      v-if="
        !loading &&
        e != 'SERVICE' &&
        e != 'ABNORMAL' &&
        e != 'SERVICE_ONE' &&
        e != 'CANCEL' &&
        sale_speeds != 'SERVICE' &&
        sale_speeds != 'ABNORMAL' &&
        sale_speeds != 'SERVICE_ONE' &&
        sale_speeds != 'CANCEL'
      "
      id="print"
    >
      <table>
        <tr>
          <td>
            <el-image
              style="width: 100px; height: 80px"
              :size="100"
              fit="contain"
              :src="logo"
            />
          </td>
          <td style="text-align: center !important; width: 100% !important">
            <span style="text-align: center; font-size: 30px; border: none"
              >购物清单</span
            >
          </td>
        </tr>
      </table>

      <!-- <div>
          <el-image style="width: 100px; height: 80px;"  :size="100" fit="contain" :src="logo"/>
           <span style="text-align: center;font-size: 30px">购物清单</span>
        </div> -->
      <div style="display: flex">
        <div>
          <el-descriptions
            title="客户信息"
            :column="3"
            style="color: #000 !important"
            :contentStyle="contentStyle"
          >
            <el-descriptions-item
              style="padding-bottom: 0; color: #000 !important"
              label-class-name="my-label"
              label="订单编号"
              >{{ order.order_sn }}</el-descriptions-item
            >
            <el-descriptions-item
              label-class-name="my-label"
              label="客户姓名"
              >{{ order.customer_name }}</el-descriptions-item
            >
            <el-descriptions-item
              label-class-name="my-label"
              label="联系电话"
              >{{ order.mobile_phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}</el-descriptions-item
            >
            <el-descriptions-item
              label-class-name="my-label"
              label="下单时间"
              >{{ order.created_at }}</el-descriptions-item
            >
            <el-descriptions-item
              label-class-name="my-label"
              label="发货时间"
              :span="2"
              >{{ order.shipping_at }}</el-descriptions-item
            >
            <el-descriptions-item
              label-class-name="my-label"
              label="收货地址"
              >{{ order.address }}</el-descriptions-item
            >
          </el-descriptions>
        </div>
        <div id="qrcode" style="float: right"></div>
      </div>

      <!-- 商品信息 -->

      <table
        class="table"
        cellspacing="0"
        width="100%"
        style="border: 1px solid #f2f5f9; margin-top: 5px"
      >
        <tr>
          <th
            colspan="5"
            style="
              font-weight: 800;
              font-size: 15px;
              border-left: 1px solid rgb(24, 24, 24);
              border-right: 1px solid rgb(24, 24, 24);
            "
          >
            商品信息
          </th>
        </tr>
        <tr>
          <!-- 车房镜片 个性参数 -->
          <td
            style="
              border-left: 1px solid rgb(24, 24, 24);
              border-right: 1px solid rgb(24, 24, 24);
            "
          >
            <span style="text-align: center">sku编码</span>
          </td>
          <td style="text-align: center !important"><span>商品sku </span></td>
          <td style="width: 8%">单价</td>
          <td style="width: 8%">数量</td>
          <td style="width: 8%; border-right: 1px solid rgb(24, 24, 24)">
            小计
          </td>
        </tr>
        <template v-for="(item, index) in order.skus">
          <tr :key="index">
            <td style="border-left: 1px solid rgb(24, 24, 24)">
              {{ item.barcode }}
            </td>
            <td>{{ item.title }} {{ item.subtitle }}</td>
            <td>**</td>
            <td>{{ item.quantity }}</td>
            <td style="border-right: 1px solid rgb(24, 24, 24)">**</td>
          </tr>
        </template>
      </table>
      <div v-if="order.mach.length != 0">
        <!-- 加工单信息 -->
        <table
          class="table"
          cellspacing="0"
          width="100%"
          style="border: 1px solid #f2f5f9"
        >
          <tr>
            <th
              colspan="6"
              style="
                font-weight: 800;
                font-size: 15px;
                border-left: 1px solid rgb(24, 24, 24);
                border-right: 1px solid rgb(24, 24, 24);
              "
            >
              加工单信息
            </th>
          </tr>
        </table>
        <div
          v-for="(item, index) in order.mach"
          :key="index"
          v-show="key == 'A' || index == key"
        >
          <table
            class="table"
            cellspacing="0"
            width="100%"
            style="border: 1px solid #f2f5f9"
          >
            <tr>
              <td style="border-left: 1px solid rgb(24, 24, 24)">加工单号</td>
              <td>顾客姓名</td>
              <td>添加时间</td>
              <td>是否来镜</td>
              <td>来镜时间</td>
              <td style="border-right: 1px solid rgb(24, 24, 24)">来镜单号</td>
            </tr>

            <tr>
              <!-- 车房镜片 个性参数 -->
              <td style="border-left: 1px solid rgb(24, 24, 24)">
                {{ item.sn }}
              </td>
              <td>{{ order.customer_name }}</td>
              <td>{{ item.created_at }}</td>
              <td :class="item.is_lj_format == '是' ? 'one' : ''">
                {{ item.is_lj_format }}
              </td>
              <td>{{ item.lj_time_at_format }}</td>
              <td style="border-right: 1px solid rgb(24, 24, 24)"></td>
            </tr>
            <tr>
              <td style="border-left: 1px solid rgb(24, 24, 24)">
                加工单类型:{{ item.order_type_format }}
              </td>
              <td>框形:{{ item.frame_type }}</td>
              <td>配戴用途:{{ item.purpose }}</td>
              <td>镜架型号:{{ item.frame_model }}</td>
              <td></td>
              <td style="border-right: 1px solid rgb(24, 24, 24)"></td>
            </tr>
          </table>

          <div class="module">
            <table
              class="table"
              cellspacing="0"
              width="100%"
              style="border: 1px solid #f2f5f9; border-collapse: collapse"
            >
              <tr>
                <th
                  style="border-left: 1px solid rgb(24, 24, 24)"
                  width="60"
                ></th>
                <th width="400">
                  商品名称
                  <img :src=" item.skus[0].brand.cover_url ?  item.skus[0].brand.cover_url : logo1" alt="" style="object-fit: cover; height: 40px" />
                </th>
                <th style="text-align: center" width="50">球镜</th>
                <th width="50">柱镜</th>
                <th width="50">轴位</th>
                <th>单眼瞳距(PD)</th>
                <th>单眼瞳高(PH)</th>
                <th style="border-right: 1px solid rgb(24, 24, 24)">ADD</th>
                <th style="border-right: 1px solid rgb(24, 24, 24)">直径</th>
              </tr>
              <tr v-for="(items, index1) in item.skus" :key="'skus' + index1">
                <td
                  style="border-left: 1px solid rgb(24, 24, 24)"
                  class="about"
                >
                  <span v-if="items.is_right == true">R</span>
                  <span v-else>L</span>
                </td>
                <!-- 车房镜片 个性参数 -->
                <td>{{ items.title }} {{ items.order_item.subtitle }}</td>
                <td>{{ items.degrees }}</td>
                <td>{{ items.zhujing }}</td>
                <td>{{ items.zhouwei }}</td>
                <td>{{ items.pd }}</td>
                <td>
                  {{
                    item.channel == "ZEISS" ||
                    item.channel == "STNOR" ||
                    item.channel == "PDD"
                      ? ""
                      : items.ph
                  }}
                </td>
                <td style="border-right: 1px solid rgb(24, 24, 24)">
                  {{ items.jadd }}
                </td>
                <td style="border-right: 1px solid rgb(24, 24, 24)">
                  {{ items.diameter }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-left: 1px solid rgb(24, 24, 24);
                    border-right: 1px solid rgb(24, 24, 24);
                  "
                >
                  原厂加工
                </td>
                <td
                  colspan="8"
                  style="border-right: 1px solid rgb(24, 24, 24); color: red"
                >
                  {{ item.yjjg.title }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style="
                    border-left: 1px solid rgb(24, 24, 24);
                    border-right: 1px solid rgb(24, 24, 24);
                  "
                >
                  <p class="item">
                    &nbsp;&nbsp;&nbsp;&nbsp;关于售后问题：如需要售后服务，须提供镜片包装袋，不可遗失，否则无法进行售后。
                  </p>
                </td>
              </tr>
              <td
                colspan="9"
                style="
                  border: 1px solid rgb(24, 24, 24);
                  border-top: 0px;
                  text-align: left !important;
                "
              >
                <p class="item2">
                  新眼镜适应问题：<br />
                  1、初次佩戴眼镜会有一个适应期，如头晕、视物放大或缩小、明亮等情况为正常反应，需1到2周适应。<br />
                  2、新眼镜佩戴没有旧眼镜清晰问题：由于旧眼镜佩戴时间比较久，当新眼镜形状大小以及镜框倾斜度和镜片度数、折射率等原因改变以后，大脑需要重新适应新眼镜，在适应过程中可能会造成不适感，需要几天来适应新的眼镜，如有带散光的眼镜就需要更长时间适应。
                </p>
              </td>
            </table>
          </div>
        </div>

        <div style="border-top: dashed 1px #000; margin: 40px -20px"></div>
        <table
          class="table"
          cellspacing="0"
          width="100%"
          style="border: 1px solid #f2f5f9"
        >
          <tr>
            <th
              colspan="6"
              style="
                border-left: 1px solid rgb(24, 24, 24);
                border-right: 1px solid rgb(24, 24, 24);
                font-weight: 800;
                font-size: 15px;
              "
            >
              加工单信息(留存)
            </th>
          </tr>
        </table>
        <div
          v-for="(item, index) in order.mach"
          :key="'mach' + index"
          v-show="index == key || key == 'A'"
        >
          <table
            class="table"
            cellspacing="0"
            width="100%"
            style="border: 1px solid #000"
          >
            <tr>
              <td style="border-left: 1px solid rgb(24, 24, 24)">加工单号</td>
              <td>顾客姓名</td>
              <td>添加时间</td>
              <td>是否来镜</td>
              <td>来镜时间</td>
              <td style="border-right: 1px solid rgb(24, 24, 24)">来镜单号</td>
            </tr>
            <tr>
              <!-- 车房镜片 个性参数 -->
              <td style="border-left: 1px solid rgb(24, 24, 24)">
                {{ item.sn }}
              </td>
              <td>{{ order.customer_name }}</td>
              <td>{{ item.created_at }}</td>
              <td :class="item.is_lj_format == '是' ? 'one' : ''">
                {{ item.is_lj_format }}
              </td>
              <td>{{ item.lj_time_at_format }}</td>
              <td style="border-right: 1px solid rgb(24, 24, 24)"></td>
            </tr>
            <tr>
              <td style="border-left: 1px solid rgb(24, 24, 24)">
                加工单类型:{{ item.order_type_format }}
              </td>
              <td>框形:{{ item.frame_type }}</td>
              <td>配戴用途:{{ item.purpose }}</td>
              <td>镜架型号:{{ item.frame_model }}</td>
              <td colspan="2">供应商:{{ item.skus[0].supplier.title }}</td>
              <!-- <td style="border-right: 1px solid rgb(24, 24, 24)"></td> -->
            </tr>
          </table>

          <div class="module">
            <table
              class="table"
              cellspacing="0"
              width="100%"
              style="border: 1px solid #f2f5f9; border-collapse: collapse"
            >
              <tr>
                <th
                  style="border-left: 1px solid rgb(24, 24, 24)"
                  width="60"
                ></th>
                <th width="400">
                  商品名称
                  <img :src=" item.skus[0].brand.cover_url ?  item.skus[0].brand.cover_url : logo1"  alt="" style="object-fit: cover; height: 40px" />
                </th>
                <th width="50">球镜</th>
                <th width="50">柱镜</th>
                <th width="50">轴位</th>
                <th>单眼瞳距(PD)</th>
                <th>单眼瞳高(PH)</th>
                <th style="border-right: 1px solid rgb(24, 24, 24)">ADD</th>
                <th style="border-right: 1px solid rgb(24, 24, 24)">直径</th>
              </tr>
              <tr v-for="(items, index1) in item.skus" :key="index1">
                <td
                  style="border-left: 1px solid rgb(24, 24, 24)"
                  class="about"
                >
                  <span v-if="items.is_right == true">R</span>
                  <span v-else>L</span>
                </td>
                <!-- 车房镜片 个性参数 -->
                <td>{{ items.title }} {{ items.order_item.subtitle }}</td>
                <td>{{ items.degrees }}</td>
                <td>{{ items.zhujing }}</td>
                <td>{{ items.zhouwei }}</td>
                <td>{{ items.pd }}</td>
                <td>{{ items.ph }}</td>
                <td style="border-right: 1px solid rgb(24, 24, 24)">
                  {{ items.jadd }}
                </td>
                <td style="border-right: 1px solid rgb(24, 24, 24)">
                  {{ items.diameter }}
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid rgb(24, 24, 24)">原厂加工</td>
                <td
                  style="border-right: 1px solid rgb(24, 24, 24); color: red"
                  colspan="8"
                >
                  {{ item.yjjg.title }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style="
                    border-left: 1px solid rgb(24, 24, 24);
                    border-right: 1px solid rgb(24, 24, 24);
                    text-align: left !important;
                    padding-left: 10px;
                  "
                >
                  加工单备注：<a style="color: red">{{ item.remark }}</a>
                </td>
              </tr>
            </table>
          </div>
          <div
            :id="item.sn"
            :ref="item.sn"
            style="float: right; margin-bottom: 20px"
          ></div>
        </div>

        <div style="font-size: 14px">订单备注：{{ order.remark }}</div>

        <!-- <div v-for="(qrcode, index) in qrcodess" :key="index" ref="qrcodeContainer"></div> -->
        <!-- <div style="font-size:14px">
                  订单备注：
                </div> -->
      </div>
    </div>
    <div id="print" v-else>
      <div>
        <table width="100%">
          <tr>
            <td>
              <div style="height: 60px" :size="100" fit="contain" />
            </td>
            <td style="text-align: center !important; width: 100% !important">
              <span style="font-size: 30px; border: none">售后作废单</span>
            </td>
          </tr>
        </table>
      </div>

      <div>
        <!-- 加工单信息 -->

        <table
          class="table"
          cellspacing="0"
          width="100%"
          style="
            border: 1px solid #f2f5f9;
            margin-top: 20px;
            border-bottom: 0px solid #000 !important;
          "
        >
          <tr
            style="
              border-right: 1px solid #000;
              border-bottom: 0px solid #000 !important;
            "
          >
            <th
              colspan="6"
              style="
                font-weight: 800;
                font-size: 15px;
                border-bottom: 0px solid #000 !important;
              "
            >
              售后信息
            </th>
          </tr>
        </table>
        <template v-for="(services, index) in machinings">
          <div :key="index">
            <div>
              <table
                class="table"
                cellspacing="0"
                width="100%"
                style="border: 1px solid #f2f5f9"
              >
                <tr style="border-top: 1px solid #000">
                  <td style="border-left: 1px solid #000">加工单号</td>
                  <td style="border-left: 1px solid #000">顾客名称</td>
                  <td>责任人</td>
                  <td>添加时间</td>
                  <td>审核状态</td>
                  <td>审核时间</td>
                  <td style="border-right: 1px solid #000 !important">类型</td>
                </tr>
                <tr>
                  <!-- 车房镜片 个性参数 -->

                  <td style="border-left: 1px solid #000">
                    {{ services.external_sn }}
                  </td>
                  <td style="border-left: 1px solid #000">
                    {{ machining.order.customer_name }}
                  </td>
                  <td>{{ services.persons.username }}</td>
                  <td>{{ services.created_at }}</td>
                  <td style="color: red">{{ services.state_format }}</td>
                  <td>{{ services.state_at }}</td>
                  <td style="border-right: 1px solid #000 !important">
                    {{ services.machining_type_format }}
                  </td>
                  <!-- <td style="border-right: 1px solid #000 !important;"></td> -->
                </tr>
                <tr>
                  <!-- <td style="services-left: 1px solid #000">
                售后分类:{{ machinings.tag_format.title }} :
                {{ services.postscript_format }}
              </td> -->
                  <td style="border-left: 1px solid #000">
                    寄回厂商:{{ services.is_send_back ? "是" : "否" }}
                  </td>
                  <td>寄回厂商时间:{{ services.back_at }}</td>
                  <td>转交库房:{{ services.is_send_huser ? "是" : "否" }}</td>
                  <td>转交库房时间:{{ services.huser_at }}</td>
                  <td style="border-right: 1px solid #000 !important"></td>
                  <td style="border-right: 1px solid #000 !important">
                    售后单号
                  </td>
                  <td style="border-right: 1px solid #000 !important">
                    {{ services.service_sn }}
                  </td>
                </tr>
                <tr style="border-right: 1px solid #000 !important;">
                  <td
                    colspan="7"
                    style="
                      border-right: 1px solid #000 !important;
                      border-left: 1px solid #000 !important;
                    "
                  >
                    供货商：{{ machining.skus[0].supplier.title }}
                  </td>
                </tr>
              </table>

              <div class="module" style="margin-top: 3px">
                <table
                  class="table"
                  cellspacing="0"
                  width="100%"
                  style="border: 1px solid #f2f5f9"
                >
                  <tr>
                    <th width="60"></th>
                    <th width="400">商品名称</th>
                    <th width="50">球镜</th>
                    <th width="50">柱镜</th>
                    <th width="50">轴位</th>
                    <th>单眼瞳距(PD)</th>
                    <th>单眼瞳高(PH)</th>
                    <th style="border-right: 1px solid #000 !important">ADD</th>
                  </tr>
                  <tr
                    style="border-left: 1px solid #000"
                    v-for="(machining, index1) in services.servicesku"
                    :key="'skus' + index1"
                  >
                    <td class="about">
                      <span v-if="machining.skus.is_right == true">R</span>
                      <span v-else>L</span>
                    </td>
                    <!-- 车房镜片 个性参数 -->
                    <td>{{ machining.skus.title }}</td>
                    <td>{{ machining.skus.degrees }}</td>
                    <td>{{ machining.skus.zhujing }}</td>
                    <td>{{ machining.skus.zhouwei }}</td>
                    <td>{{ machining.skus.pd }}</td>
                    <td>{{ machining.skus.ph }}</td>
                    <td style="border-right: 1px solid #000 !important">
                      {{ machining.skus.jadd }}
                    </td>
                  </tr>
                  <tr>
                    <td style="border-left: 1px solid #000">售后原因</td>
                    <td style="border-right: 1px solid #000" colspan="8">
                      {{ services.postscript }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              :id="services.service_sn"
              style="float: right; margin-bottom: 20px"
            ></div>
          </div>
        </template>
      </div>
      <!-- <div style="margin-top: 50px;">
  
        <table
          class="table"
          cellspacing="0"
          width="100%"
          style="border: 1px solid #f2f5f9"
        >
          <tr style="border-right: 1px solid #000">
            <th colspan="6" style="font-weight: 800; font-size: 15px">
              加工单信息
            </th>
          </tr>
        </table>
        <div>
          <table
            class="table"
            cellspacing="0"
            width="100%"
            style="border: 1px solid #f2f5f9"
          >
            <tr style="">
              <td style="border-left: 1px solid #000;">加工单号</td>
              <td>顾客姓名</td>
              <td>添加时间</td>
              <td v-if="machining.service != undefined">作废时间</td>
              <td>是否来镜</td>
              <td>来镜时间</td>
              <td style="border-right: 1px solid #000 !important">来镜单号</td>
            </tr>
            <tr>
      
              <td style="border-left: 1px solid #000">{{ machining.sn }}</td>
             <td>{{ machining.order.customer_name }}</td> 
              <td>{{ machining.created_at }}</td>
              <td v-if="machining.service != undefined">
                {{ machining.service.created_at }}
              </td>
              <td style="color: red">{{ machining.is_lj_format }}</td>
              <td>{{ machining.lj_time_at_format }}</td>
              <td style="border-right: 1px solid #000 !important"></td>
            </tr>
            <tr>
              <td style="border-left: 1px solid #000">
                加工单类型:{{ machining.order_type_format }} 
              </td>
              <td>框形:{{ machining.frame_type }}</td>
              <td>配戴用途:{{ machining.purpose }}</td>
              <td>镜架型号:{{ machining.frame_model }}</td>
              <td></td>
              <td></td>
              <td style="border-right: 1px solid #000 !important"></td>
            </tr>
          </table>

          <div class="module" style="margin-top: 3px">
            <table
              class="table"
              cellspacing="0"
              width="100%"
              style="border: 1px solid #f2f5f9"
            >
              <tr>
                <th width="60"></th>
                <th width="400">商品名称</th>
                <th width="50">球镜</th>
                <th width="50">柱镜</th>
                <th width="50">轴位</th>
                <th>单眼瞳距(PD)</th>
                <th>单眼瞳高(PH)</th>
                <th style="border-right: 1px solid #000 !important">ADD</th>
                <th style="border-right: 1px solid #000 !important">直径</th>
              </tr>
              <tr
                style="border-left: 1px solid #000"
                v-for="(machining, index1) in machining.skus"
                :key="'skus' + index1"
              >
                <td class="about">
                  <span v-if="machining.is_right == true">R</span>
                  <span v-else>L</span>
                </td>
            
                <td>
                {{ machining.title }} {{ machining.order_item.subtitle }} 
                </td>
                <td>{{ machining.degrees }}</td>
                <td>{{ machining.zhujing }}</td>
                <td>{{ machining.zhouwei }}</td>
                <td>{{ machining.pd }}</td>
                <td>{{ machining.ph }}</td>
                <td style="border-right: 1px solid #000 !important">
                  {{ machining.jadd }}
                </td>
                <td style="border-right: 1px solid #000 !important">
                  {{ machining.diameter }}
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #000">退片原因</td>
                <td style="border-right: 1px solid #000" colspan="8">
                  {{ machining.delete_remark }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div id="qrcodes" style="float: right; margin-top: 20px;"></div> -->
    </div>
  </div>
</template>
<script>
import print from "vue-print-nb";
import QRcode from "qrcodejs2";
import { ordersShow, ordersPrint, machiningShow, printss } from "@/api";

export default {
  name: "views.orders.Print",
  inject: ["handelLoading"],
  directives: { print },
  data() {
    return {
      machining: "",
      ids: "",
      about: "R",
      id: 0,
      key: "A",
      e: "",
      items: [],
      loading: false,
      order: { store_id: 0, skus: [], store: {}, channel: "JD", mach: [] },
      Print: true,
      logo1: "",
      qrcodess: [],
      qrcodeContents: [],
      qrcodeContentss: [],
      machinings: [],
      services: [],
      contentStyle: {
        color: "#000",

        "font-soze": "14px",
      },
    };
  },
  computed: {
   
    logo: function () {
     console.log("@/assets/" + this.order.channel + "_logo.png");
      return require("@/assets/" + this.order.channel + "_logo.png");
    },
  },
  methods: {
    handelOrderPrint() {
      // 记录订单被打印
      ordersPrint(this.id).then((response) => {
        console.log(response);
      });
    },
    handelPriceFormatter(row, column) {
      console.log(column);
      return this.order.store_id == 0 ? "**" : row.price;
    },
    handelTotalFormatter(row, column) {
      console.log(column);
      return this.order.store_id == 0 ? "**" : row.price * row.quantity;
    },
    handleLoad() {
      if (this.ids != undefined) {
        machiningShow(this.ids).then((response) => {
          this.machining = response.data;
          // console.log(this.order);
          console.log(this.machining, "333444555");
        });
      }
      this.handelLoading(true);
      ordersShow(this.id).then((response) => {
        this.handelLoading((this.loading = false));
        this.order = response.data;
        this.qrcodes();
        this.qrcodesss();
        this.qrcodessy();
        this.qrcode();
        console.log(this.order.mach);
        this.logo = "@/assets/" + this.order.channel + "_logo.png";
        if (this.order.mach[0] != undefined && this.order.mach.length > 0) {
          console.log("213");
          if (this.order.mach[0].skus[0].brand.cover_url == "") {
            console.log("1");
          } else {
            var a = new RegExp("http://localhost");
            var b = this.order.mach[0].skus[0].brand.cover_url.replace(a, "");
            this.logo1 = b;
          }
        }
      });
    },

    dayin() {
      if (
        this.e != "undefined" &&
        this.e != "SERVICE" &&
        this.e != "ABNORMAL" &&
        this.e != undefined
      ) {
        var sn = {
          sn: this.machining.sn,
        };
        // console.log(this.order.mach, this.machining.sn, "123123123");
        printss(this.id, sn).then((response) => {
          console.log(response);
        });
      }

      window.print();
    },
    qrcodessy() {
      setTimeout(() => {
        for (let index = 0; index < this.machinings.length; index++) {
          console.log(this.machinings[index].service_sn);
          this.qrcodeContentss.push(this.machinings[index].service_sn);

          new QRcode(this.machinings[index].service_sn, {
            text: this.machinings[index].service_sn, //需要转换为二维码的内容
            width: 80, //宽
            height: 80, //高
          });
        }
      }, 100);
    },
    qrcode() {
      setTimeout(() => {
        new QRcode("qrcode", {
          text: this.order.order_sn, //需要转换为二维码的内容
          width: 80, //宽
          height: 80, //高
        });
      }, 100);
    },
    qrcodes() {
      setTimeout(() => {
        console.log(this.machining);
        new QRcode("qrcodes", {
          text: this.machining.sn, //需要转换为二维码的内容
          width: 80, //宽
          height: 80, //高
        });
      }, 100);
    },
    qrcodesss() {
      setTimeout(() => {
        for (let index = 0; index < this.order.mach.length; index++) {
          this.qrcodeContents.push(this.order.mach[index].sn);
          console.log(this.order.mach[index]);
          new QRcode(this.order.mach[index].sn, {
            text: this.order.mach[index].sn,
            width: 80,
            height: 80,
          });
        }
      }, 100);
    },
  },
  mounted: function () {},

  created() {
    //  console.log(~~this.this.$route.params);
    console.log();
    this.id = this.$route.params.id;
    this.key = this.$route.params.key;
    this.e = this.$route.params.e;
    this.ids = this.$route.params.ids;
    this.sale_speeds = this.$route.params.sale_speed;
    this.machinings = this.$route.params.servicess;
    //console.log(this.machinings,  this.$route.params, this.sale_speeds);
    this.handleLoad();
  },
};
</script>
<style lang="scss" scoped>
.one {
  color: red;
}
#detail {
  .module {
    border-radius: 0px !important;
    .table {
      tr {
        padding: 0;
        td {
          padding: 0;
          .el-button.el-button--primary {
            font-size: 14px;
            border: none;
          }

          .el-button.el-button--default {
            width: 90px;
            color: #3d7fe9;
          }
        }
      }
    }
  }
  .table {
    tr {
      th {
        font-size: 14px;
        color: #000;
        font-weight: 500;
        border: 1px solid rgb(24, 24, 24);
        // border-right: 0px ;
        // border-left: 0px ;
        //
        text-align: center;
        padding: 0;
        box-sizing: border-box;

        &:last-child {
          border-right: 0;
        }

        .tips {
          font-size: 14px;
        }
      }

      td {
        // text-align: center !important;
        padding: 0;
        height: 41px;
        font-size: 14px;
        box-sizing: border-box;
        border-right: 1px solid rgb(24, 24, 24);
        border-bottom: 1px solid rgb(24, 24, 24);
        text-align: center;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  .primary {
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid rgb(24, 24, 24);

    .el-button.el-button--primary {
      width: 100px;
      height: 40px;

      border-radius: 5px;
      border: none;
      margin-left: 20px;
      font-size: 14;
    }
  }
}
.item {
  width: 100%;
  color: red;
  font-size: 17px;
  align-items: center;
  display: flex;
}
.item2 {
  color: #093785;
  font-size: 14px;
  padding: 5px 0;
  margin-left: 20px;
}
.el-descriptions-item__cell {
  padding-bottom: 0 !important;
}
.el-descriptions__header {
  margin-bottom: 0;
}
#detail {
  .module {
    border-radius: 0px;
    .table {
      tr {
        padding: 0;
        td {
          padding: 0;
          .el-button.el-button--primary {
            font-size: 14px;
            border: none;
          }

          .el-button.el-button--default {
            width: 90px;
            color: #3d7fe9;
          }
        }
      }
    }
  }
  .table {
    border-collapse: collapse;
    tr {
      th {
        font-size: 14px;
        color: #000;
        font-weight: 500;
        border: 1px solid #000;
        padding: 0;
        box-sizing: border-box;

        &:last-child {
          border-right: 0;
        }

        .tips {
          font-size: 14px;
        }
      }

      td {
        text-align: center;
        padding: 0;
        height: 41px;
        font-size: 14px;
        box-sizing: border-box;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  .primary {
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #e6e6e6;

    .el-button.el-button--primary {
      width: 100px;
      height: 40px;

      border-radius: 5px;
      border: none;
      margin-left: 20px;
      font-size: 14;
    }
  }
}
.item {
  width: 100%;
  color: red;
  font-size: 17px;
  align-item: center;
  display: flex;
}
.item2 {
  color: #093785;
  font-size: 14px;
  padding: 5px 0;
  margin-left: 20px;
}
.el-descriptions-item__cell {
  padding-bottom: 0 !important;
}
.el-descriptions__header {
  margin-bottom: 0;
}
</style>

<style media="print" lang="scss">
.my-label {
  color: #000 !important;
}
.el-descriptions__header {
  margin-bottom: 0;
}

@media print {
  .buttonNoPrint {
    display: none;
  }
  td {
    height: 0 !important;
    text-align: center !important;
    padding: 0 !important;
  }
  th {
    padding: 0 !important;
  }
  .about {
    text-align: center !important;
  }
}
@page {
  size: auto;
  margin: 3mm;
}
@media print {
  html {
    background-color: #ffffff;
    height: auto;
    margin: 0px;
  }
  body {
    border: solid 1px #ffffff;
    /* margin: 10mm 15mm 10mm 15mm; */
  }
  #printMe table {
    table-layout: auto !important;
  }
  #printMe .el-table__header-wrapper .el-table__header {
    width: 100% !important;
    border: solid 1px #f2f2f2;
  }
  #printMe .el-table__body-wrapper .el-table__body {
    width: 100% !important;
  }
  #printMe #pagetable table {
    table-layout: fixed !important;
  }
}
</style>
